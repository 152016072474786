<template>
  <div class="system-hint">
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <div class="title">系统提示</div>
          <p class="ping">APP线路检测失败</p>
          <div class="subTitle">解决方案：</div>
          <p>1.请检测您的网络是否正常，手机是否开了VPN或10分钟后再尝试访问。</p>
          <p>2.前往官网地址 fenu.cc 下载最新版。</p>
          <p>3.前往Potato粉柚官方交流群</p>
          <div class="btn" @click="toGroup">前往群聊</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  name: 'SystemHint',
  props: ['show'],
  methods: {
    toGroup() {
      window.location.href = 'https://pzcp.org/fenyou';
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 295px;
  height: 305px;
  padding: 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  .title {
    font-size: 18px;
    color: rgb(136, 136, 136);
    text-align: center;
    margin-bottom: 23px;
  }
  .subTitle {
    color: rgb(51, 51, 51);
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: rgb(51, 51, 51);
  }
  .ping {
    color: rgb(255, 103, 143);
    margin: 0px 0 8px;
  }
  .btn {
    height: 38px;
    width: 231px;
    border-radius: 10px;
    margin: 0 auto;
    line-height: 38px;
    text-align: center;
    color: white;
    font-size: 14px;
    background: linear-gradient(rgb(247, 74, 130), rgb(252, 169, 132));
    margin-top: 30px;
  }
}
</style>
